import React from "react"
import PrivacyPolicyTemplate from "../../../components/PrivacyPolicyTemplate"

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyTemplate
      name="Point Vision Marseille"
      mail="contact_donnees_personelles@giepointvision.fr"
    />
  )
}

export default PrivacyPolicy
